.app {
  background-color: #968a65;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

body {
  overflow-x: hidden
}

.accordion {
  background-color: #968a65;
  font-size: calc(10px + 2vmin);
  color: #968a65;
  margin-bottom: 10px;
  max-width: 100%;
}

.accordion > .accordionSummary {
  background-color: #d6d1c2;
}